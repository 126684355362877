import React from "react";
import "../Pages/Style.css";

import Header from "./Header";
// import BackOfficeAsidebar from "../Backoffice/BackofficeComponents/Trackers/BackOfficeAsidebar";
import { useSelector } from "react-redux";

import HrSidebar from "../HrDepartment/HrSideBar";
import HRHeader from "../HrDepartment/HRHeader";

function Aside({ children }) {
  // const { user } = useSelector((state) => ({ ...state.auth }));
  // // const department = "HR"

  // const department = user?.response?.userDetails?.departmentName;
  return (
    <>
     
      <HRHeader />
      <HrSidebar />
      {children}
    </>
  );
}

export default Aside;
