import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { CgOrganisation } from "react-icons/cg";
import Accordion from "react-bootstrap/Accordion";
import { IoMdSettings } from "react-icons/io";

import { GoDotFill } from "react-icons/go";
import users from "../Assets/user.png";
import { FiUser } from "react-icons/fi";
import { HiOutlineCurrencyRupee } from "react-icons/hi2";
import { IoMdAdd } from "react-icons/io";
import "./HrDepartment.scss"
function HrSidebar() {
  const { user } = useSelector((state) => ({ ...state.auth }));
  const imageBaseUrl = process.env.REACT_APP_BASE_URL;
  const profileimage = user?.response?.userDetails?.profileimage;
  const userName = user?.response?.userDetails?.name;
  const email = user?.response?.userDetails?.email;
  const logo = process.env.REACT_APP_LOGO_URL;

  const OrganisationList = [
    {
      name: "Dashboard",
      url: "/orgdashboard",
    },
    { name: "Employees", url: "/emp_Directory" },
    { name: "Organization Structure", url: "/org-Structure" },
    { name: "Onboarding", url: "/Onboarding" },
    { name: "Documents", url: "/Documents" },
    { name: "Engage", url: "/Engage" },
    { name: "Exits", url: "/Exits" },
  ];
  const meList = [
    { name: "LeaveDashboard", url: "/LeaveDashboard" },
    { name: "EmployeeAttendance", url: "/EmployeeAttendance" },
  ];

  return (
    <>
      <div className="left-sidebar purchase-asidebar">
        <div className="brand">
          <Link to="/dashboard" className="logo">
            <span>
              <img
                src={logo}
                alt="logo-large"
                className="logo-lg logo-light"
                style={{ width: "185px" , height: "60px" , marginTop : "16px" }}

              />
              <img
                src={logo}
                alt="logo-large"
                className="logo-lg logo-dark"
                style={{ width: "185px" , height: "60px" , marginTop : "16px" }}

              />
            </span>
          </Link>
        </div>
        {/* <div className="sidebar-user-pro media border-end">
          <div className="position-relative mx-auto">
            <img
              src={imageBaseUrl + profileimage}
              alt="user"
              className="rounded-circle thumb-md"
            />
          </div>
          <div className="media-body ms-2 user-detail align-self-center">
            <h5 className="font-14 m-0 fw-bold">{userName} </h5>
            <p
              className="opacity-50 mb-0"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "78%",
              }}
            >
              {email}
            </p>
          </div>
        </div> */}
        {/* <div className="border-end">
          <ul className="nav nav-tabs menu-tab nav-justified" role="tablist">
            <li className="nav-item text-light fw-bold">PURCHASE DEPARTMENT</li>
          </ul>
        </div> */}
        <div className="menu-content h-100 pt-5" data-simplebar>
          <div className="menu-body navbar-vertical">
            <div
              className="collapse navbar-collapse tab-content"
              id="sidebarCollapse"
            >
              <ul
                className="navbar-nav tab-pane active"
                id="Main"
                role="tabpanel"
              >
                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                    color: "black",
                  }}
                >
                  <NavLink className="inactive-link" to="/dashboard">
                    <i className="fas fa-home"></i> &nbsp;&nbsp; Dashboard
                  </NavLink>
                </li>
                {/* <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                  }}
                >
                  <NavLink
                    className="inactive-link"
                    to="/profile"
                  >
                      <div className="d-flex justify-content-between">
                        <div>
    <FiUser/>
    &nbsp;&nbsp; Me  </div>
    <div><IoMdAdd/></div>
  </div>
                  </NavLink>
                </li> */}
                <li>
                  <Accordion className="purchase-asidebar-accordian">
                    <Accordion.Item
                      eventKey="0"
                      className="purchase-management"
                      // className="inactive-link"
                    >
                      <Accordion.Header>
                        <FiUser style={{ fontSize: "20px" }} /> &nbsp;&nbsp; Me
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          {meList.map((element, index) => {
                            return (
                              <li className="nav-item" key={index}>
                                <NavLink className="nav-link" to={element.url}>
                                  <GoDotFill></GoDotFill>&nbsp;&nbsp;{" "}
                                  {element.name}
                                </NavLink>
                              </li>
                              
                            );
                          })}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  
                  </Accordion>
                </li>
                <li>
                  <Accordion className="purchase-asidebar-accordian">
                    <Accordion.Item
                      eventKey="0"
                      className="purchase-management"
                      // className="inactive-link"
                    >
                      <Accordion.Header>
                        <CgOrganisation style={{ fontSize: "20px" }} />{" "}
                        &nbsp;&nbsp; Organisation
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          {OrganisationList.map((element, index) => {
                            return (
                              <li className="nav-item" key={index}>
                                <NavLink className="nav-link" to={element.url}>
                                  <GoDotFill></GoDotFill>&nbsp;&nbsp;{" "}
                                  {element.name}
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="1" className="settings">
                      <Accordion.Header>
                        <IoMdSettings />
                        &nbsp;&nbsp; Settings
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          {settingList.map((element, index) => {
                            return (
                              <li className="nav-item" key={index}>
                                <NavLink className="nav-link" to={element.url}>
                                  <GoDotFill></GoDotFill>&nbsp;&nbsp;{" "}
                                  {element.name}
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item> */}
                  </Accordion>
                </li>
                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                    color: "black",
                  }}
                >
                  <NavLink className="inactive-link" to="/Timedashboard">
                    <i className="fas fa-home"></i> &nbsp;&nbsp; Time
                  </NavLink>
                </li>
                <li
                  className="nav-item"
                  style={{
                    padding: ".7rem 1rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                    margin: ".5rem 0",
                  }}
                >
                  <NavLink className="inactive-link" to="/payroll">
                    <div className="d-flex justify-content-between">
                      <div>
                        <HiOutlineCurrencyRupee />
                        &nbsp;&nbsp; Payroll
                      </div>
                      <div>
                        {" "}
                        <IoMdAdd />
                      </div>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        ;
      </div>
    </>
  );
}

export default HrSidebar;
