import axios from "axios";

// const devEnv = process.env.NODE_ENV !== "production";

// const { REACT_APP_DEV_API } = process.env;

const apiurl = process.env.REACT_APP_DEV_API_KEY_NEW;
const API = axios.create({
  //baseURL: "http://13.200.102.128:8001/api/",
  baseURL: apiurl,
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile"))?.response?.token
    }`;
  }
  return req;
});


export const signIn = (formData) => API.post("/login", formData);
// export const signUp = (formData) => API.post("/users/signup", formData);
export const createDepart = (formData) =>
  API.post("/storedepartment", formData);
export const getUsers = (data) => API.post("/getuserlist", data);
// export const getProjects = (data) => API.post("/getrolelist", data);
export const getProjects = (data) => API.post("/untiprojectdropdown", data);
export const getrailwayzone = (data) => API.post("/getrailwayzonelist", data);
export const getprojecttypelist = (data) =>
  API.post("/getprojecttypelist", data);
export const getunitlist = (data) => API.post("/getunitlist", data);
export const getemdpaidlist = (data) => API.post("/getemdpaidlist", data);
export const getsdpaidstatuslist = (data) =>
  API.post("/getsdpaidstatuslist", data);
export const getsdpaidthroughlist = (data) =>
  API.post("/getsdpaidthroughlist", data);
export const getpgpaidstatuslist = (data) =>
  API.post("/getpgpaidstatuslist", data);
export const getpgpaidthroughlist = (data) =>
  API.post("getpgpaidthroughlist", data);
export const getworkstatuslist = (data) => API.post("/getworkstatuslist", data);
export const getclientcategorylist = (data) =>
  API.post("/getclientcategorylist", data);
export const getTicketTypeList = (data) => API.post("/gettickettypelist", data);
export const ProjectUser = (data) => API.post("/ProjectsUsers", data);
export const getUserDropdown = (data) => API.post("/getuserdropdown", data);
export const getAllDepartments = (data) =>
  API.post("/getdepartmentlistsimple", data);
export const getRoleList = (data) => API.post("/getrolelist", data);
export const getScheduleDropdown = (data) => API.post("/getschedulelist", data);
export const getAdminList = (data) => API.post("/getadminlist", data);
export const getPermissionList = (data) => API.post("permissionlist", data);
export const getAdminDropDown = (data) => API.post("getadmindropdown", data);
export const departmentDropdown = (data) =>
  API.post("departmentdropdown", data);
export const getsubdivisionwithzone = (data) =>
  API.post("getsubdivisionwithzone", data);
export const getBoardGroup = (data) => API.post("getboarddropdown", data);
export const getGroupStatus = (data) => API.post("listboardgroupstatus", data);
export const getInspectionAgency = (data) =>
  API.post("listinspectionagency", data);
export const getProjectCategory = (data) =>
  API.post("getprojectcategorylist", data);
export const getTenderList = (data) => API.post("gettenderlist", data);
export const getboardgroupstatus = (data) =>
  API.post("getboardgroupstatusdropdown", data);
export const getTenderDashboard = (data) =>
  API.post("gettenderdashboard", data);
export const getNotification = (data) => API.post("getmynotifications", data);
export const getUpcomingTenders = (data) =>
  API.post("getupcomingtenderslist", data);
export const getWarrantyTrackerList = (data) =>
  API.post("getwarrantytrakerlist", data);
export const getProjectCoachList = (data) =>
  API.post("getprojectlotcoachlist", data);
export const getProjectLotList = (data) => API.post("getprojectlotlist", data);
export const getProjectWiseCoachFurnishedlist = (data) =>
  API.post("getprojectwisecoachfurnishedlist", data);
export const getPendingWarrantyTrackerList = (data) =>
  API.post("getpendingwarrantytrakerlist", data);
export const getLotsLogsList = (data) =>
  API.post("lot-amendment-logs-list", data);
export const getTendetLogsList = (data) => API.post("gettenderloglist", data);
export const getTenderByCategoryApi = (data) =>
  API.post("getStoreDashboardData", data);
export const getTenderUser = (data) =>
  API.post("gettenderauthoriseduserlist", data);
export const signatureUpdateApi = (data) =>
  API.post("updateusersignature", data);
