import React from "react";
import { IoIosSearch } from "react-icons/io";
import notification from "../Assets/39-Notification.png";
import { Link } from "react-router-dom";
import ellipse from "../Assets/Ellipse 1.png";
import "./HrDepartment.scss";
import BasicMenu from "./MaterialUiComponent/BasicMenu";

const HRHeader = () => {
  const profile = JSON.parse(localStorage.getItem("profile"));

  const name = profile?.response?.userDetails?.name;
  const department = profile?.response?.userDetails?.departmentName;
  const getInitials = (name) => {
    const namesArray = name?.split(" ");
    const initials = namesArray?.map((namePart) => namePart[0].toUpperCase());

    return initials?.join("");
  };

  return (
    <>
      <div className="topbar" style={{ backgroundColor: "#4667B6" }}>
        <div className="hr-header">
          <nav className="navbar-custom" id="navbar-custom">
            <ul className="list-unstyled topbar-nav float-start mb-0">
              <li style={{ position: "relative" }}>
                <input className="border_None rounded  py-2" placeholder="Search Here"style={{paddingLeft:"5rem",paddingRight:"5rem"}} />{" "}
                <span className="search-Button">
                  <IoIosSearch />
                </span>
                 
              </li>
            </ul>
            <ul className="list-unstyled topbar-nav float-end mb-0">
              <div className="d-flex">
                <li>
                  <img
                    src={notification}
                    alt="notification"
                    className="rounded me-2 px-2 py-2  border"
                    style={{ backgroundColor: "white" }}
                  />
                </li>
                <li>
                  {" "}
                  <img
                    src={ellipse}
                    alt="profile-user"
                    className="rounded-circle me-2 thumb-sm"
                  />
                </li>
                <li>
                  <div>
                    <span
                      className="d-none d-md-block fw-semibold font-12 text-capitalize"
                      style={{ color: "black" }}
                    >
                      {name}
                    </span>
                    <small
                      className="d-none d-md-block font-11"
                      style={{ color: "black" }}
                    >
                      {getInitials(department)}
                    </small>
                  </div>
                </li>
                <li>
                  <BasicMenu />
                </li>
              </div>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default HRHeader;
