import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./features/authSlice";
import currentPageReducer from "./features/currentPageSlice";
import dropdownSlice from "./features/allDropdownSlice.js";
// import currentTenderReducer from "../Tender/TenderSlice.js";
// import dateReducer from "./../Backoffice/Common/Redux/projectDateSlice.js";
// import backOfficeReducer from "../Backoffice/backOfficeSlice.js";
// import purchaseOrderListSlice from "../Purchase/ManagmentPages/PurchaseOrder/purchaseOrderListSlice.js";
import JobTitleSlice from "./features/Hr/JobTitleSlice.js";
import ProbationPolicySlice from "./features/Hr/ProbationPolicySlice.js";
import TimeTypeSlice from "./features/Hr/TimeTypeSlice.js";
import LoaderSlice from "./features/Hr/LoaderSlice.js";
import WorkerTypeSlice from "./features/Hr/WorkerTypeSlice.js";
import LegalEntitySlice from "./features/Hr/LegalEntitySlice.js";
import { businessUnitListApi } from "../HrDepartment/Organisation/organisationStructure/OrganizationComponents/organizationStructureApi.js";
import BusinessUnitslice from "./features/Hr/BusinessUnitslice.js";
export default configureStore({
  reducer: {
    auth: AuthReducer,
    currentPage: currentPageReducer,
    dropdown: dropdownSlice,
    // currentTender: currentTenderReducer,
    // date: dateReducer,
    // backOffice: backOfficeReducer,
    // purchaseOrderList: purchaseOrderListSlice,
    legalEntityList:LegalEntitySlice,
    businessUnitList:BusinessUnitslice,
    jobTitleList:JobTitleSlice,
    ProbationPolicyList:ProbationPolicySlice,
    TimeTypeLists:TimeTypeSlice,
    WorkerTypeLists:WorkerTypeSlice,
    loader:LoaderSlice
  
  },
});
