import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Research1 from "../../Assets/research1.png";
import { Card } from "react-bootstrap";

import "../HrDepartment.scss"
import {
  AreaChart,
  Area,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const OrganistaionDashboard = () => {
const [numberOfEmployee,setNumberOfEmployee]=useState([[
  {
  "num_of_employee": 40,
  "last_month_emp_percentage": "+8%",
  "status": "All"
  },
  {
  "num_of_employee": 40,
  "last_month_emp_percentage": "+8%",
  "status": "Registered"
  },
  {
  "num_of_employee": 40,
  "last_month_emp_percentage": "+8%",
  "status": "Not Invited"
  }
 ]
 ]);
console.log(numberOfEmployee)

const navigate = useNavigate();
const goToAddEmployee =()=>{
  navigate("/add_employee");
}
  const loginData = [
    { day: "Day 1", loginCount: 10 },
    { day: "Day 2", loginCount: 15 },
    { day: "Day 3", loginCount: 20 },
  ];

  return (
    <>

 
     
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid">
            <span className="dashboard">DASHBOARD</span>
            <div className="row margin_top">
              <div className="col-lg-5">
                <div className="gap_24">
                  <div className="emp_fontStyle">Employees</div>
                  <div className="employee_count">
              
                      <div className="total_employee" style={{width:"180px"}}>
                      <span className="fourty">40</span>
                      <div className="absolute_fix">
                        <span className="">Total Employee</span>
                        <br />
                        <span className="lastMonth">+9% in last month</span>
                      </div>
                      </div>
               
                      <div className="registered" style={{width:"180px"}}>
                      <span className="fourty">40</span>
                      <div className="absolute_fix">
                        <span className="">Total Employee</span>
                        <br />
                        <span className="lastMonth">+8% in last month</span>
                      </div>
                    </div>
                 
                    <div className="not_invited" style={{width:"180px"}}>
                      <span className="fourty">40</span>
                      <div className="absolute_fix">
                        <span className="">Total Employee</span>
                        <br />
                        <span className="lastMonth">+8% in last month</span>
                      </div>
                
                    </div>
                   
                  </div>
                </div>
                <div className=" gap_24 margin_top">
                  <div className="emp_fontStyle">Quicklinks</div>
                  <div className="employee_count d_flex_space">
                    <button className=" button_quickLink marginRight" onClick={()=>goToAddEmployee()}>Add New Employee</button>
                    <button className=" button_quickLink marginLeft" >New Announcement</button>
                  </div>
                  <div className="employee_count d_flex_space">
                    <button className=" button_quickLink marginRight" >Add New Employee</button>
                    <button className=" button_quickLink marginLeft" >New Announcement</button>
                  </div>
                  <div className="employee_count d_flex_space">
                    <button className=" button_quickLink marginRight" >Add New Employee</button>
                    <button className=" button_quickLink marginLeft" >New Announcement</button>
                  </div>
                </div>
                <div className=" gap_24 margin_top">
                  <div className="emp_fontStyle">Bulk Operations</div>
                  <div className="employee_count d_flex_space">
                    <button className=" button_quickLink marginRight" >Add New Employee</button>
                    <button className=" button_quickLink marginLeft" >New Announcement</button>
                  </div>
                  <div className="employee_count d_flex_space">
                    <button className=" button_quickLink marginRight" >Add New Employee</button>
                    <button className=" button_quickLink marginLeft" >New Announcement</button>
                  </div>
                  <div className="employee_count d_flex_space">
                    <button className=" button_quickLink marginRight" >Add New Employee</button>
                    <button className=" button_quickLink marginLeft" >New Announcement</button>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="card" >
                  <div className="row">
                    <div className="d-flex justify-content-between ">
                      <h5
                        style={{ paddingLeft: "1rem", paddingTop: "1rem" }}
                        className="emp_login_fontStyle"
                      >
                        Employee Login Summary
                      </h5>
                      <select className="days_selector_style">
                        <option>Last 30Days</option>
                        <option>Last 15Days</option>
                      </select>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <AreaChart width={700} height={300} data={loginData}>
                    
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="day" />
                        <YAxis />
                        <Tooltip />
                        <Area
                          type="monotone"
                          dataKey="loginCount"
                          stroke="#8884d8"
                          fill="#8884d8"
                        />
                      </AreaChart>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "1.5rem" }}>
                  <div className="col-md-6" style={{ width: "50%" }}>
                    <div className="card" style={{ height: "422px" }}>
                      <h4 className="emp_login_fontStyle">New Hires</h4>
                      <div
                        className="mx-auto"
                        style={{ translate: " -8% 82%" }}
                      >
                        <img src={Research1} />
                        <p
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          No New Hires
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-6"
                    style={{ width: "50%", height: "458px" }}
                  >
                    <div className="card" style={{ height: "422px" }}>
                      <div className="d-flex justify-content-between">
                        <h5 className="emp_login_fontStyle">Exits</h5>
                        <h5
                          className="emp_login_fontStyle"
                          style={{ marginRight: "16px", color: "red" }}
                        >
                          {" "}
                          View All
                        </h5>
                      </div>
                      <div>
                        <div className="d-flex justify-content-between ">
                          <div className="d-flex align-items-center">
                            <p className="abbreviation_circle">PS</p>
                            <div>
                              <h6 className="emp_name">Priya Singh</h6>
                              <p className="job_title">SEO Executive</p>
                            </div>
                          </div>

                          <p
                            style={{
                              marginTop: "20px",
                              marginLeft: "2rem",
                              marginRight: "2rem",
                            }}
                          >
                            20mar2024
                          </p>
                        </div>
                        <hr></hr>
                      </div>
                      <div>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex align-items-center">
                            <p className="abbreviation_circle">PS</p>
                            <div>
                              <h6 className="emp_name">Priya Singh</h6>

                              <p className="job_title">SEO Executive</p>
                            </div>
                          </div>

                          <p
                            style={{
                              marginTop: "20px",
                              marginLeft: "2rem",
                              marginRight: "2rem",
                            }}
                          >
                            20mar2024
                          </p>
                        </div>
                        <hr></hr>
                      </div>
                      <div>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex align-items-center">
                            <p className="abbreviation_circle">PS</p>
                            <div>
                              <h6 className="emp_name">Priya Singh</h6>

                              <p className="job_title">SEO Executive</p>
                            </div>
                          </div>

                          <p
                            style={{
                              marginTop: "20px",
                              marginLeft: "2rem",
                              marginRight: "2rem",
                            }}
                          >
                            20mar2024
                          </p>
                        </div>
                        <hr></hr>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default OrganistaionDashboard;
