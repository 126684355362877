import { useEffect, useState } from "react";

import "./App.css";

import "typeface-poppins";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import Login from "./Login/Login";
import { setUser } from "./redux/features/authSlice";
import PrivateRoute from "./Components/PrivateRoute";
import ForgotPassword from "./Login/ForgotPassword";
// import AnalyticsIndex from "./Pages/AnalyticsIndex";
import { useDispatch, useSelector } from "react-redux";
import VerifyOtp from "./Login/VerifyOtp";
import ChangePassword from "./Login/ChangePassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import ProjectDepartAddNew from './Pages/ProjectDepartAddNew'

import Home from "./Components/Home";
import Aside from "./Components/Aside";
import { fetchDropdown } from "./redux/features/allDropdownSlice.js";

import OrganistaionDashboard from "./HrDepartment/Organisation/OrganisationDashboard.js";

import AddEmployee from "./HrDepartment/Organisation/AddEmployee.js";
import EmployeeDetail from "./HrDepartment/Organisation/EmployeeDetail/EmployeeDetail.js";

import LeaveDashboard from "./HrDepartment/LeaveDashboard/LeaveDashboard/LeaveDashboard.js";
import EmployeeAttendance from "./HrDepartment/LeaveDashboard/EmployeeAttendance";
// import LeaveDashboard from "./LeaveDashboard/LeaveDashboard/LeaveDashboard.js";
// import EmployeeAttendance from "./LeaveDashboard/EmployeeAttendance.js";
import OrganisationStructure from "./HrDepartment/Organisation/organisationStructure/OrganisationStructure.js";

import EmployeeMainTab from "./HrDepartment/Employees/EmployeeMainTab.js";
import ExitsMain from "./HrDepartment/Organisation/OrganisationExits/ExitMainTab.js";
import TimeDashboard from "./HrDepartment/Time/TimeDashboard.js";

import HrPrivateRoute from "./HrDepartment/HrPrivateRoute.js";

function App() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({ ...state.auth })); //Important;

  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const getToken = userProfile?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const [isLoading, setLoading] = useState(true);

  function someRequest() {
    return new Promise((resolve) => setTimeout(() => resolve(), 2500));
  }

  useEffect(() => {
    document.title = process.env.REACT_APP_TITLE || "Title";
    someRequest().then(() => {
      const loaderElement = document.querySelector(".loader-container");
      if (loaderElement) {
        loaderElement.remove();
        setLoading(!isLoading);
      }
    });
  });

  useEffect(() => {
    if (user_id) {
      dispatch(fetchDropdown({ user_id, config }));
    }
  }, [user_id, dispatch]);
  useEffect(() => {
    dispatch(setUser(userProfile));
  }, []);
  if (isLoading) {
    //
    return null;
  }

  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <Routes>
          {/* auth routes start */}
          <Route exact path="/" element={<Home></Home>}></Route>
          <Route exact path="/login" element={<Login />}></Route>
          <Route exact path="*" element={<Home />}></Route>
          <Route
            exact
            path={"/ForgotPassword"}
            element={<ForgotPassword />}
          ></Route>
          <Route exact path="/VerifyOtp" element={<VerifyOtp />}></Route>
          <Route
            exact
            path="/ChangePassword"
            element={<ChangePassword />}
          ></Route>
          {/* auth routes end */}
          {/* protected routes start */}
          <Route
            exact
            path="/Dashboard"
            element={
              <HrPrivateRoute>
                <Aside>
                  <Dashboard />
                </Aside>
                </HrPrivateRoute>
            }
          />

          {/* HR Dashboard Routes */}
          <Route
            exact
            path="/orgdashboard"
            element={
              <HrPrivateRoute>
                <Aside>
                  <OrganistaionDashboard />
                </Aside>
              </HrPrivateRoute>
            }
          />
          <Route
            exact
            path="/Timedashboard"
            element={
              <HrPrivateRoute>
                <Aside>
                  <TimeDashboard />
                </Aside>
              </HrPrivateRoute>
            }
          />
          <Route
            exact
            path="/emp_Directory"
            element={
              <HrPrivateRoute>
                <Aside>
                  <EmployeeMainTab />
                </Aside>
              </HrPrivateRoute>
            }
          />
          <Route
            exact
            path="/add_employee"
            element={
              <HrPrivateRoute>
                <Aside>
                  <AddEmployee />
                </Aside>
              </HrPrivateRoute>
            }
          />
          <Route
            exact
            path="/employee_detail/:id"
            element={
              <HrPrivateRoute>
                <Aside>
                  <EmployeeDetail />
                </Aside>
              </HrPrivateRoute>
            }
          />
          <Route
            exact
            path="/LeaveDashboard"
            element={
              <HrPrivateRoute>
                <Aside>
                  <LeaveDashboard />
                </Aside>
              </HrPrivateRoute>
            }
          />
          <Route
            exact
            path="/EmployeeAttendance"
            element={
              <HrPrivateRoute>
                <Aside>
                  <EmployeeAttendance />
                </Aside>
              </HrPrivateRoute>
            }
          />
          <Route
            exact
            path="/org-structure"
            element={
              <HrPrivateRoute>
                <Aside>
                  <OrganisationStructure />{" "}
                </Aside>
              </HrPrivateRoute>
            }
          />
          <Route
            exact
            path="/Exits"
            element={
              <HrPrivateRoute>
                <Aside>
                  <ExitsMain />
                </Aside>
              </HrPrivateRoute>
            }
          />
          {/* Purchase Order Approval Routes */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
