import React from "react";
import Footer from "../Components/Footer";



import OrganistaionDashboard from "../HrDepartment/Organisation/OrganisationDashboard";
function Index() {
  const user = JSON.parse(localStorage.getItem("profile"));
  const department = user?.response?.userDetails?.departmentName;
  console.log("department",department);

  return (
    <>
      {/* {department === "Tender" ? (
        <TenderDashboard />
      ) : department === "Purchase" ? (
        <PurchaseDashboard />
      ) : department === "Store" ? (
        <StoreDashboard />
      ): department === "Human Resource" ? (
        <OrganistaionDashboard />
      ) : (
        <BackOfficeDashboard />
      )} */}
       <OrganistaionDashboard />
      <Footer />
    </>
  );
}


export default Index;
